export const navigationAtTop = () => ({
  background: 'none',
  color: '#fff'
});

export const navigationScrolled = () => ({
  background: '#fff'
});

export const subMenu = () => ({
  left: -16
});

export const mobileMenuIcon = () => ({
  width: 35,
  height: 35,
  cursor: 'pointer'
});

export const mobileMenuOpen = () => ({
  transform: 'translateX(0%)'
});

export const mobileMenuClose = () => ({
  transform: 'translateX(100%)'
});
