import { useStaticQuery, graphql } from 'gatsby';

export const useSocialMedia = () => {
  const { contentfulSocialMedia } = useStaticQuery(
    graphql`
      query SiteMetaData {
        contentfulSocialMedia {
          email
          facebook
          instagram
          twitter
          youTube
        }
      }
    `
  );
  return contentfulSocialMedia;
};
