import React, { useState, useEffect } from 'react';
import Radium from 'radium';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Headroom from 'react-headroom';
import { Fade } from 'react-reveal';
import { AiOutlineMenu as MenuIcon } from 'react-icons/ai';
import { MdClose as CloseIcon } from 'react-icons/md';
import * as styles from '../styles/navigation.styles';
import OutlineButton from './outline-button';
import Button from './button';
import { isAndAbove, isAndBelow } from '../util';

const menuItems = [
  {
    title: 'About',
    path: '/about',
    subItems: [
      {
        title: 'Our History',
        path: '/about#history'
      },
      {
        title: 'Our Team',
        path: '/about#team'
      }
    ]
  },
  {
    title: 'Services',
    path: '/services'
  },
  {
    title: 'Artists',
    path: '/artists'
  },
  {
    title: 'Contact',
    path: '/contact'
  },
  {
    title: 'News',
    path: '/news'
  },
  {
    title: 'Shop',
    path: '/shop'
  }
];

const Navigation = () => {
  const { contentfulHeaderLogo } = useStaticQuery(graphql`
    query HeaderLogoQuery {
      contentfulHeaderLogo {
        logoOnLightBackground {
          title
          file {
            url
          }
        }
        logoOnDarkBackground {
          title
          file {
            url
          }
        }
      }
    }
  `);

  const [logo, setLogo] = useState(contentfulHeaderLogo.logoOnDarkBackground || {});
  const [buttonTextColor, setButtonTextColor] = useState('white');
  const [navigationStyle, setNavigationStyle] = useState(styles.navigationAtTop() || {});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const listenScrollEvent = () => {
    if (window.scrollY > 100) {
      setLogo(contentfulHeaderLogo.logoOnLightBackground);
      setButtonTextColor('blue');
      setNavigationStyle(styles.navigationScrolled());
    } else {
      setLogo(contentfulHeaderLogo.logoOnDarkBackground);
      setButtonTextColor('white');
      setNavigationStyle(styles.navigationAtTop());
    }
  };

  const logoHeight = isAndAbove('md', breakpoints) ? 70 : 50;

  return (
    <Headroom className={isAndBelow('sm', breakpoints) && !isMenuOpen ? 'overflow-hidden' : ''}>
      <nav role="navigation" className="w-full z-10 transition duration-300 ease-in-out" style={navigationStyle}>
        <div className="flex justify-between items-center py-2 px-6 lg:px-12 xl:px-24">
          {logo.file && (
            <Fade>
              <Link to="/">
                <img src={logo.file.url} alt={logo.title} style={{ height: logoHeight, width: 'auto', margin: 0 }} />
              </Link>
            </Fade>
          )}
          {isAndAbove('md', breakpoints) ? (
            <Fade delay={500}>
              <div className="flex items-center">
                {menuItems.map((item, index) => (
                  <div
                    key={index}
                    className="group inline-block relative ml-6 lg:ml-10 tracking-wider lg:text-lg hover:text-blue uppercase">
                    <Link to={item.path}>{item.title}</Link>
                    <ul
                      className="animate-rise absolute hidden pt-2 text-base group-hover:block"
                      style={styles.subMenu()}>
                      {item.subItems &&
                        item.subItems.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className="bg-gray-200 hover:text-white hover:bg-blue py-2 px-4 block whitespace-nowrap">
                            <AnchorLink to={subItem.path}>{subItem.title}</AnchorLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
                <Link to={'/contact'}>
                  <OutlineButton
                    className="ml-6 lg:ml-10"
                    textColor={buttonTextColor}
                    text="BOOK NOW"
                    size={breakpoints.md && 'xs'}
                  />
                </Link>
              </div>
            </Fade>
          ) : (
            <div>
              <MenuIcon style={styles.mobileMenuIcon()} onClick={() => setIsMenuOpen(true)} />
              <nav
                className="p-6 transition duration-300 ease-out h-screen w-screen absolute overflow-x-scroll text-gray-800 bg-white top-0 left-0"
                style={isMenuOpen ? styles.mobileMenuOpen() : styles.mobileMenuClose()}>
                <div className="flex justify-end">
                  <CloseIcon style={styles.mobileMenuIcon()} onClick={() => setIsMenuOpen(false)} />
                </div>
                <Fade top cascade when={isMenuOpen} delay={200} duration={500}>
                  <ul className="text-xl font-bold tracking-wide uppercase list-none">
                    {menuItems.map((item, index) => (
                      <li key={index} className="my-8 flex justify-center items-center">
                        <Link to={item.path}>{item.title}</Link>
                        {/*{item.subItems && <ArrowsRightIcon className="ml-1 h-5 w-5 text-blue" />}*/}
                      </li>
                    ))}
                    <li>
                      <div className="flex justify-center">
                        <Link to={'/contact'}>
                          <Button className="" text="BOOK NOW" />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </Fade>
              </nav>
            </div>
          )}
        </div>
      </nav>
    </Headroom>
  );
};

export default Radium(Navigation);
