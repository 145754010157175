import React from 'react';
import Radium from 'radium';

const OutlineButton = ({ className, style, size, text, textColor }) => {
  const getSizeClasses = () => {
    switch (size) {
      case 'xs':
        return 'text-xs px-3 py-2 border-2';
      case 'sm':
        return 'text-sm px-4 py-2 border-2';
      case 'xl':
        return 'text-xl px-8 py-4 border-4';
      default:
        return 'text-base px-6 py-3 border-2';
    }
  };

  const colorClass = textColor ? `text-${textColor}` : '';

  return (
    <button
      type="button"
      className={[
        className,
        getSizeClasses(),
        colorClass,
        'inline-flex items-center border-blue rounded-full tracking-widest hover:bg-blue hover:text-white transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue'
      ].join(' ')}
      style={style}>
      {text}
    </button>
  );
};

export default Radium(OutlineButton);
