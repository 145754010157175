import React, { useState } from 'react';
import Radium from 'radium';
import { Link, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import get from 'lodash/get';
import { Fade } from 'react-reveal';
import { ImQuotesLeft as QuotesLeft, ImQuotesRight as QuotesRight } from 'react-icons/im';
import { AiFillInstagram as InstagramIcon } from 'react-icons/ai';
import {
  FaTwitter as TwitterIcon,
  FaLinkedin as LinkedinIcon,
  FaFacebookSquare as FacebookIcon,
  FaEnvelope as EmailIcon
} from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';
import TeamMember from '../components/team-member';
import Footer from '../components/footer';
import { isAndAbove } from '../util';

const AboutIndex = props => {
  const pageContent = get(props, 'data.contentfulAboutPage');
  const imua = get(props, 'data.contentfulImua');
  const teamMembers = get(props, 'data.allContentfulTeamMember.nodes');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState({});

  const breakpoints = useBreakpoint();

  const paddingTop = isAndAbove('md', breakpoints) ? 86 : 66;

  const ourHistoryJson = get(pageContent, 'ourHistory.json');
  const ourHistoryRenderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-6 text-lg sm:text-xl leading-relaxed">{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => {
        const href = node.data.uri;
        return href.includes('http') ? (
          <a className="text-blue-lighter" href={href} target="_blank">
            {children}
          </a>
        ) : (
          <Link className="text-blue-lighter" to={href}>
            {children}
          </Link>
        );
      }
    }
  };

  const imuaBioJson = get(imua, 'bio.json');
  const imuaBioRenderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-4 text-lg">{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => {
        const href = node.data.uri;
        return href.includes('http') ? (
          <a className="text-blue" href={href} target="_blank">
            {children}
          </a>
        ) : (
          <Link className="text-blue" to={href}>
            {children}
          </Link>
        );
      }
    }
  };

  const openModal = teamMember => {
    setSelectedTeamMember(teamMember);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTeamMember({});
    setIsModalOpen(false);
  };

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title="About" />
      <div
        className="bg-gray-900"
        style={{
          paddingTop: paddingTop
        }}>
        <div
          style={{
            height: '44vw',
            background: `url('${pageContent.image.file.url}') no-repeat center center/cover`
          }}
        />
      </div>
      <div id="history" className="bg-gray-900">
        <div className="m-auto px-6 py-12 md:py-24 text-white" style={{ maxWidth: 1280 }}>
          <div className="text-3xl sm:text-4xl leading-8 font-extrabold text-blue-lighter">OUR HISTORY</div>
          <Fade delay={300}>{documentToReactComponents(ourHistoryJson, ourHistoryRenderOptions)}</Fade>
        </div>
      </div>
      {/*<svg*/}
      {/*  xmlns="http://www.w3.org/2000/svg"*/}
      {/*  version="1.1"*/}
      {/*  width="100%"*/}
      {/*  height="100"*/}
      {/*  viewBox="0 0 100 100"*/}
      {/*  preserveAspectRatio="none"*/}
      {/*  style={{ transform: 'scaleX(-1)' }}>*/}
      {/*  <path fill="#1a202c" stroke="#1a202c" d="M0 0 C 50 100 80 100 100 0 Z" />*/}
      {/*</svg>*/}
      <div id="team" className="m-auto px-6 py-12 md:py-24" style={{ maxWidth: 1280 }}>
        <p className="mb-6 sm:mb-12 text-3xl sm:text-4xl leading-8 font-extrabold text-blue">OUR TEAM</p>
        <Fade delay={300}>
          <div className="mb-8 sm:mb-16">
            <img
              className="mr-12 mb-8 rounded-xl"
              style={{
                width: isAndAbove('md', breakpoints) ? '50%' : '100%',
                float: isAndAbove('md', breakpoints) && 'left'
              }}
              src={imua.image.file.url}
              alt={imua.image.title}
            />
            <div>
              <div className="text-3xl sm:text-4xl leading-8 font-extrabold uppercase">{imua.name}</div>
              <div className="sm:text-lg leading-8 font-bold text-blue tracking-wide uppercase">{imua.position}</div>
              <div className="flex text-gray-500 mb-4">
                {imua.instagram && (
                  <a href={imua.instagram} target="_blank">
                    <InstagramIcon size={25} className="mr-2" />
                  </a>
                )}
                {imua.facebook && (
                  <a href={imua.facebook} target="_blank">
                    <FacebookIcon size={25} className="mr-2" />
                  </a>
                )}
                {imua.twitter && (
                  <a href={imua.twitter} target="_blank">
                    <TwitterIcon size={25} className="mr-2" />
                  </a>
                )}
                {imua.linkedIn && (
                  <a href={imua.linkedIn} target="_blank">
                    <LinkedinIcon size={25} className="mr-2" />
                  </a>
                )}
              </div>
              {documentToReactComponents(imuaBioJson, imuaBioRenderOptions)}
            </div>
          </div>
        </Fade>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-8 lg:gap-12">
          {teamMembers.map((member, index) => (
            <Fade bottom>
              <TeamMember key={index} content={member} onClick={openModal} />
            </Fade>
          ))}
        </div>
        <Modal content={selectedTeamMember} isOpen={isModalOpen} onClose={closeModal} />
      </div>
      <div className="bg-gray-900">
        <Fade delay={300}>
          <div
            className="m-auto px-6 pt-12 md:pt-24 pb-8 flex flex-col justify-center items-center text-center text-white tracking-wide italic"
            style={{ maxWidth: 1280, fontFamily: `'Charm', cursive` }}>
            <div className="relative max-w-3xl text-2xl md:text-3xl">
              <span className="relative z-10">
                Be enthusiastic to serve the Lord, keeping your passion toward him boiling hot! Radiate with the glow of
                the Holy Spirit and let him fill you with excitement as you serve him.
              </span>
              <QuotesLeft
                className="absolute text-blue"
                size={isAndAbove('md', breakpoints) ? 100 : 70}
                style={{
                  top: isAndAbove('md', breakpoints) ? -32 : -24,
                  left: isAndAbove('md', breakpoints) ? -16 : -8
                }}
              />
              <QuotesRight
                className="absolute text-blue"
                size={isAndAbove('md', breakpoints) ? 100 : 70}
                style={{
                  bottom: isAndAbove('md', breakpoints) ? -16 : -4,
                  right: isAndAbove('md', breakpoints) ? -16 : -8
                }}
              />
            </div>
            <div className="mt-2 text-xl md:text-2xl">Romans 12:11 TPT</div>
          </div>
        </Fade>
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

const Modal = ({ content, isOpen, onClose }) => {
  const bio = get(content, 'bio.json');
  const bioRenderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-sm sm:text-base text-gray-700">{children}</p>
    }
  };

  return (
    isOpen &&
    content && (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-800 opacity-90" />
          </div>
          {/*This element is to trick the browser into centering the modal contents.*/}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline">
            <div className="bg-white p-8 pb-4 sm:px-10">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex-shrink-0 h-24 w-24">
                  <img className="rounded-full" src={content.image.file.url} alt={content.image.title} />
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-4">
                  <div className="text-xl leading-6 font-extrabold uppercase text-center sm:text-left">
                    {content.name}
                  </div>
                  <div className="leading-7 font-bold text-blue tracking-wide uppercase text-center sm:text-left">
                    {content.position}
                  </div>
                  <div className="flex text-gray-500 justify-center sm:justify-start">
                    {content.instagram && (
                      <a href={content.instagram} target="_blank">
                        <InstagramIcon size={25} className="mr-2" />
                      </a>
                    )}
                    {content.facebook && (
                      <a href={content.facebook} target="_blank">
                        <FacebookIcon size={25} className="mr-2" />
                      </a>
                    )}
                    {content.twitter && (
                      <a href={content.twitter} target="_blank">
                        <TwitterIcon size={25} className="mr-2" />
                      </a>
                    )}
                    {content.linkedIn && (
                      <a href={content.linkedIn} target="_blank">
                        <LinkedinIcon size={25} className="mr-2" />
                      </a>
                    )}
                    {content.email && (
                      <a href={`mailto:${content.email}`}>
                        <EmailIcon size={25} className="mr-2" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:flex sm:items-start">
                {bio ? (
                  documentToReactComponents(bio, bioRenderOptions)
                ) : (
                  <div className="py-2 text-center text-gray-500">No bio available</div>
                )}
              </div>
            </div>
            <div className="flex justify-end bg-gray-100 px-8 py-4 sm:px-10">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Radium(AboutIndex);

export const pageQuery = graphql`
  query AboutIndexQuery {
    contentfulAboutPage {
      image {
        file {
          url
        }
      }
      ourHistory {
        json
      }
    }
    contentfulImua {
      name
      position
      image {
        title
        file {
          url
        }
      }
      instagram
      facebook
      twitter
      linkedIn
      bio {
        json
      }
    }
    allContentfulTeamMember(sort: { fields: order }) {
      nodes {
        name
        position
        image {
          title
          file {
            url
          }
        }
        instagram
        email
      }
    }
  }
`;
