import React from 'react';
import Radium from 'radium';
import { AiFillInstagram as InstagramIcon } from 'react-icons/ai';
import {
  FaTwitter as TwitterIcon,
  FaLinkedin as LinkedinIcon,
  FaFacebookSquare as FacebookIcon,
  FaEnvelope as EmailIcon
} from 'react-icons/fa';

const TeamMember = ({ content, onClick }) => {
  return (
    <div className="flex flex-col my-6 sm:my-0">
      <img
        className="mb-4 rounded-xl cursor-pointer"
        src={content.image.file.url}
        alt={content.image.title}
        onClick={() => onClick(content)}
      />
      <div className="text-2xl leading-8 font-extrabold uppercase">{content.name}</div>
      <div className="leading-8 font-bold text-blue tracking-wide uppercase">{content.position}</div>
      <div className="flex text-gray-500">
        {content.instagram && (
          <a href={content.instagram} target="_blank">
            <InstagramIcon size={25} className="mr-2" />
          </a>
        )}
        {content.facebook && (
          <a href={content.facebook} target="_blank">
            <FacebookIcon size={25} className="mr-2" />
          </a>
        )}
        {content.twitter && (
          <a href={content.twitter} target="_blank">
            <TwitterIcon size={25} className="mr-2" />
          </a>
        )}
        {content.linkedIn && (
          <a href={content.linkedIn} target="_blank">
            <LinkedinIcon size={25} className="mr-2" />
          </a>
        )}
        {content.email && (
          <a href={`mailto:${content.email}`}>
            <EmailIcon size={25} className="mr-2" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Radium(TeamMember);
