import _ from 'lodash';

/*
 * Breakpoint Functions
 */
export const isAndAbove = (breakpoint, breakpoints) => {
  if (!breakpoints) {
    return false;
  }

  switch (breakpoint) {
    case 'xs':
      return true;
    case 'sm':
      return breakpoints.sm || breakpoints.md || breakpoints.lg || breakpoints.xl || breakpoints.xxl;
    case 'md':
      return breakpoints.md || breakpoints.lg || breakpoints.xl || breakpoints.xxl;
    case 'lg':
      return breakpoints.lg || breakpoints.xl || breakpoints.xxl;
    case 'xl':
      return breakpoints.xl || breakpoints.xxl;
    case 'xxl':
      return breakpoints.xxl;
    default:
      return false;
  }
};

export const isAndBelow = (breakpoint, breakpoints) => {
  if (!breakpoints) {
    return false;
  }

  switch (breakpoint) {
    case 'xs':
      return breakpoints.xs;
    case 'sm':
      return breakpoints.sm || breakpoints.xs;
    case 'md':
      return breakpoints.md || breakpoints.sm || breakpoints.xs;
    case 'lg':
      return breakpoints.lg || breakpoints.md || breakpoints.sm || breakpoints.xs;
    case 'xl':
      return breakpoints.xl || breakpoints.lg || breakpoints.md || breakpoints.sm || breakpoints.xs;
    case 'xxl':
      return true;
    default:
      return false;
  }
};

/**
 * If all field of NESTED obj don't have data => return true
 * @param {*} obj
 */
export const isDeepEmpty = obj => {
  if (_.isEmpty(obj)) {
    return true;
  }
  if (typeof obj === 'object') {
    for (const item of Object.values(obj)) {
      if ((item !== undefined && typeof item !== 'object') || !isDeepEmpty(item)) {
        return false;
      }
    }
    return true;
  }
  return _.isEmpty(obj);
};

/**
 *
 * @param {string} email
 */
export const isValidEmail = email => {
  // https://emailregex.com/
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 *
 * @param {string} name
 */
export const isValidName = name => {
  // eslint-disable-next-line no-useless-escape
  const re = /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|[0-9]/;
  return !re.test(name);
};
