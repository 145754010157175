import React from 'react';
import Radium from 'radium';
import { Link } from 'gatsby';
import {
  FaEnvelope as EmailIcon,
  FaFacebookF as FacebookIcon,
  FaInstagram as InstagramIcon,
  FaTwitter as TwitterIcon,
  FaYoutube as YoutubeIcon
} from 'react-icons/fa';
import { useSocialMedia } from '../hooks/use-social-media';
import { isAndAbove, isAndBelow } from '../util';

const Footer = ({ breakpoints }) => {
  const socials = useSocialMedia();
  return (
    <div>
      <div className="bg-gray-900">
        <div
          className="m-auto px-6 py-6 md:py-16 flex flex-wrap flex-col md:flex-row text-gray-500"
          style={{ maxWidth: 1536 }}>
          <div className="flex flex-col justify-between">
            <img
              className="mb-4"
              style={{ width: 125 }}
              src="https://images.squarespace-cdn.com/content/v1/57ff4b6e03596ec2ff5a4692/1604990026713-ZRWBV0EUZE22PSCYS9SB/ke17ZwdGBToddI8pDm48kGLJA4Y-3wBIFxPQBbIsUoN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0n5chk9rBASQpsP_m4yiJ38_TGe9LtUQ8BNX6qMIY28tCefElg_zxWas-sS8Z7JFFQ/3000Square-SideInitial-White-short.png?format=2500w"
            />
            <div className="text-sm mb-6">
              <div>4211 Waialae Avenue #1030</div>
              <div>Honolulu, HI 96816</div>
              <div>Hours by appointment only.</div>
            </div>
          </div>
          <div className="flex flex-grow flex-wrap flex-col md:flex-row md:justify-end">
            <div className="flex flex-col mb-6 md:ml-16 xl:ml-32">
              <div className="text-blue-lighter font-bold uppercase text-lg mb-4">About Zeo Music</div>
              <Link className="mb-2" to={'/about#history'}>
                Our History
              </Link>
              <Link className="mb-2" to={'/about#team'}>
                Our Team
              </Link>
              <Link className="mb-2" to={'/services'}>
                Services
              </Link>
            </div>
            <div className="flex flex-col mb-6 md:ml-16 xl:ml-32">
              <div className="text-blue-lighter font-bold uppercase text-lg mb-4">Artists</div>
              <Link className="mb-2" to={'/news'}>
                Latest Updates
              </Link>
              <Link className="mb-2" to={'/artists'}>
                Artist Roster
              </Link>
            </div>
            <div className="flex flex-col mb-6 md:ml-16 xl:ml-32">
              <div className="text-blue-lighter font-bold uppercase text-lg mb-4">Connect With Us</div>
              <Link className="mb-2" to={'/contact'}>
                Booking Inquiries
              </Link>
              <a className="mb-2" href={'mailto:glenn@zeomusic.com'}>
                Ask a Question
              </a>
              <a className="mb-2" href={'mailto:tiffany@tiffanythurston.com'}>
                Press
              </a>
              {/*<a className="mb-2">Newsletter</a>*/}
              {/*<a className="mb-2">Social Media</a>*/}
            </div>
          </div>
        </div>
        {isAndBelow('sm', breakpoints) && (
          <div className="flex flex-col text-center text-gray-500 text-sm">
            <div className="flex justify-center">
              <a href={`mailto:${socials.email}`}>
                <EmailIcon style={{ height: 20, width: 20, margin: '0.5rem' }} />
              </a>
              <a href={socials.instagram} target="_blank">
                <InstagramIcon style={{ height: 20, width: 20, margin: '0.5rem' }} />
              </a>
              <a href={socials.facebook} target="_blank">
                <FacebookIcon style={{ height: 20, width: 20, margin: '0.5rem' }} />
              </a>
              <a href={socials.twitter} target="_blank">
                <TwitterIcon style={{ height: 20, width: 20, margin: '0.5rem' }} />
              </a>
              <a href={socials.youTube} target="_blank">
                <YoutubeIcon style={{ height: 20, width: 20, margin: '0.5rem' }} />
              </a>
            </div>
            {/* <a className="mb-1">Privacy Policy</a>
            <a className="mb-1">Terms of Service</a> */}
            <div className="mb-4">© 2021 Zeo Music LLC. All rights reserved.</div>
          </div>
        )}
      </div>
      {isAndAbove('md', breakpoints) && (
        <div className="bg-blue">
          <div
            className="m-auto px-6 py-3 flex justify-between text-white text-sm tracking-wide"
            style={{ maxWidth: 1536 }}>
            <div>© 2021 Zeo Music LLC. All rights reserved.</div>
            <div className="flex">
              {/* <a className="ml-4">Terms of Service</a>
              <a className="ml-4">Privacy Policy</a> */}
              <div className="flex">
                <a href={`mailto:${socials.email}`}>
                  <EmailIcon style={{ height: 20, width: 20, marginLeft: '1rem' }} />
                </a>
                <a href={socials.instagram} target="_blank">
                  <InstagramIcon style={{ height: 20, width: 20, marginLeft: '1rem' }} />
                </a>
                <a href={socials.facebook} target="_blank">
                  <FacebookIcon style={{ height: 20, width: 20, marginLeft: '1rem' }} />
                </a>
                <a href={socials.twitter} target="_blank">
                  <TwitterIcon style={{ height: 20, width: 20, marginLeft: '1rem' }} />
                </a>
                <a href={socials.youTube} target="_blank">
                  <YoutubeIcon style={{ height: 20, width: 20, marginLeft: '1rem' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Radium(Footer);
