import React from 'react';
import Radium from 'radium';
import classNames from 'classnames';

const Button = ({ className, onClick, style, size, text, color }) => {
  const getSizeClasses = () => {
    switch (size) {
      case 'xs':
        return 'text-xs px-3 py-2 border-2';
      case 'sm':
        return 'text-sm px-4 py-2 border-2';
      case 'xl':
        return 'text-xl px-8 py-4 border-4';
      case '2xl':
        return 'text-2xl px-16 py-5 border-4';
      default:
        return 'text-base px-6 py-3 border-2';
    }
  };

  const bgColor = color ? `bg-${color} border-${color} focus:ring-${color}` : 'bg-blue border-blue focus:ring-blue';

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'inline-flex items-center text-white uppercase rounded-full tracking-widest hover:opacity-75 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
        bgColor,
        getSizeClasses(),
        className
      )}
      style={style}>
      {text}
    </button>
  );
};

export default Radium(Button);
