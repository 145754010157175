import React from 'react';
import Radium from 'radium';
import { Link } from 'gatsby';
import base from './base.css';
import Container from './container';
import Navigation from './navigation';
import { isAndAbove } from '../util';

const Layout = ({ location, breakpoints, children }) => {
  let header;

  let rootPath = `/`;
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`;
  }

  const headerHeight = isAndAbove('md', breakpoints) ? -86 : -66;

  return (
    <Container>
      <Navigation />
      <div style={{ marginTop: headerHeight }}>{children}</div>
    </Container>
  );
};

export default Radium(Layout);
